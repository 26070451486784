import { consentRoute } from './routes/consent/consent.route';
import { consentGrantedRoute } from './routes/consent-granted/consent-granted.route';
import { FamilyPortalLayoutPageModule } from './../components/family-portal-layout-page/family-portal-layout-page.module';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { loginRoute } from './routes/login/login.route';
import { rootRoute } from './routes/root/root.route';
import { guardianAgreementRoute } from './routes/guardian-agreement/guardian-agreement.route';
import { notFoundRoute } from './routes/not-found/not-found.route';
import { homeRoute } from './routes/home/home.route';
import { messagesRoute } from './routes/messages/messages.route';
import { myAccountRoute } from './routes/my-account/my-account.route';
import { LoginGuard } from './guards/login-guard/login-guard';
import { callbackRoute } from './routes/callback/callback.route';
import { CurrentUserService } from '../services/current-user/current-user.service';
import { ApiModule } from '../services/api/api-module';
import { UserInactivity } from '../services/user-inactivity/user-inactivity.service';
import { HomeGuard } from './guards/home-guard/home-guard';

const routes: Routes = [
  rootRoute,
  loginRoute,
  consentRoute,
  guardianAgreementRoute,
  consentGrantedRoute,
  homeRoute,
  messagesRoute,
  myAccountRoute,
  notFoundRoute,
  callbackRoute,
];

const options: ExtraOptions = {
  useHash: false,
  enableTracing: true, // TEMP for dev purpose, will remove later
  paramsInheritanceStrategy: 'always',
  bindToComponentInputs: true, // enable this to bind route params to component inputs
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, options),
    // Modules used by funtional guards
    ApiModule,
    FamilyPortalLayoutPageModule,
  ],
  exports: [RouterModule],
  providers: [
    // GUARDS
    LoginGuard,
    HomeGuard,
    // Services used by funtional guards
    CurrentUserService,
    UserInactivity,
  ],
})
export class AppRoutingModule { }
